import { Footer } from '@sections/Footer'
import { Header } from '@sections/Header'
import { Block, Flexbox } from '@stage-ui/core'
import { GatsbyBrowser } from 'gatsby'

export const Layout: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return (
    <Block w="100vw" style={{ overflow: 'hidden' }}>
      <Header />
      {element}
      <Flexbox flex={1} />
      <Footer />
    </Block>
  )
}
