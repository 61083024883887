/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const metrikaGoal = (
  event: string,
  params?: Record<string, string | number | string[] | number[]> | undefined,
) => {
  // @ts-ignore
  if (window?.ym) {
    // @ts-ignore
    window.ym(87300724, 'reachGoal', event, params)
  }
}
