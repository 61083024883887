import { Flexbox, Link } from '@stage-ui/core'

type MenuItem = {
  label: string
  href: string
}

type MenuProps = {
  items: MenuItem[]
  logo: React.ReactNode
  extra: React.ReactNode
}

export const MainMenu = (props: MenuProps) => {
  const { items, logo, extra } = props
  return (
    <Flexbox alignItems="center" flex={1}>
      {logo}
      {items.map((item) => (
        <Link
          ml="2rem"
          key={item.label + item.href}
          size="s"
          color="gray600"
          style={{
            textTransform: 'uppercase',
          }}
          href={item.href}
        >
          {item.label}
        </Link>
      ))}
      {extra}
    </Flexbox>
  )
}
