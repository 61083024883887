import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

const PageWrap = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox
      px="l"
      m="0 auto"
      flexGrow={10}
      style={{
        minWidth: '20rem',
        maxWidth: '73rem',
      }}
      column
      {...props}
    />
  )
}

export default PageWrap
