import React from 'react'

import theme from '@foods-n-goods/client/theme'
import { Viewport } from '@stage-ui/core'
import { globalStyles } from '@src/styles'
import { Helmet } from 'react-helmet'
import cover from '@assets/images/cover.png'
import appleTouchIcon from '@assets/favicon/apple-touch-icon.png'

import favicon32 from '@assets/favicon/favicon-32x32.png'
import favicon16 from '@assets/favicon/favicon-16x16.png'
import safariPinnedTab from '@assets/favicon/safari-pinned-tab.svg'

const title = 'Foods & Goods'
const description = 'Автоматизация бизнеса для поставщиков HoReCa'
const contentColor = '#ffffff'
const maskIconColor = '#3a9642'

export const App: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    // @ts-ignore
    <Viewport theme={theme} global={globalStyles}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:image" content={cover} />
        <meta name="twitter:image" content={cover} />
        <meta name="msapplication-TileColor" content={contentColor} />
        <meta name="theme-color" content={contentColor} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href={safariPinnedTab} color={maskIconColor} />
        <meta name="yandex-verification" content="540fd9f919e94f0f" />
        {/* <script src="//code.jivosite.com/widget/NQxGohsxe6" async /> */}
        <script type="text/javascript">
          {`
          ;(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          var z = null;m[i].l=1*new Date();
          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
       
          ym(87300724, "init", {
               clickmap:true,
               trackLinks:true,
               accurateTrackBounce:true,
               webvisor:true
          });
					`}
        </script>
        <noscript>
          {`<img src="https://mc.yandex.ru/watch/87300724" style="position: absolute; left: -9999px" alt="" />`}
        </noscript>
      </Helmet>
      {children}
    </Viewport>
  )
}
