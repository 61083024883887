import Apple from '@assets/images/button_apple.svg'
import Google from '@assets/images/button_google.svg'

import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type ButtonAppMarketProps = {
  type: 'apple' | 'google'
} & FlexboxTypes.Props
const ButtonAppMarket = (props: ButtonAppMarketProps) => {
  const { type, ...restProps } = props
  const image = type === 'apple' ? Apple : Google
  return (
    <Flexbox
      {...restProps}
      style={[
        {
          opacity: 0.5,
          width: '8rem',
          height: '2.5rem',
          background: `url(${image}) no-repeat`,
          zIndex: 1,
          // @ts-ignore
          ...restProps.style,
        },
      ]}
    />
  )
}

export default ButtonAppMarket
