import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

export const SHLogo = (props: FlexboxTypes.Props & { color?: string }) => {
  const { color = 'black', ...rest } = props
  return (
    <Flexbox {...rest}>
      <svg
        width="166"
        height="46"
        viewBox="0 0 166 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M71.0479 22.4195C69.3269 22.4195 67.8893 22.0392 66.6947 21.2585C65.5001 20.4978 64.67 19.4369 64.1841 18.0958L66.6947 16.6345C67.4236 18.6362 68.9017 19.6171 71.1289 19.6171C72.2223 19.6171 73.0524 19.3969 73.5991 18.9765C74.1458 18.5562 74.4292 17.9957 74.4292 17.3151C74.4292 16.5945 74.1255 16.054 73.5383 15.6737C72.9512 15.2933 71.8983 14.873 70.3798 14.4326C69.5294 14.1723 68.841 13.9522 68.3145 13.732C67.7881 13.5118 67.2414 13.2115 66.6542 12.8112C66.0671 12.4108 65.6419 11.9304 65.3382 11.3499C65.0547 10.7694 64.913 10.0688 64.913 9.28811C64.913 7.72675 65.4799 6.50569 66.5935 5.58489C67.7071 4.66409 69.0637 4.22371 70.643 4.22371C72.0603 4.22371 73.3156 4.56401 74.3685 5.2446C75.4416 5.92519 76.2515 6.88602 76.8387 8.08707L74.3685 9.48828C73.6193 7.84686 72.3842 7.02614 70.643 7.02614C69.7926 7.02614 69.1244 7.22632 68.6182 7.60665C68.1121 8.007 67.8488 8.52745 67.8488 9.20804C67.8488 9.86861 68.1121 10.369 68.6182 10.7494C69.1447 11.1297 70.076 11.5301 71.4326 11.9504C72.1008 12.1706 72.607 12.3308 72.9107 12.4308C73.2144 12.5309 73.6598 12.7111 74.2268 12.9513C74.7937 13.1915 75.2189 13.4117 75.5023 13.6519C75.7858 13.8921 76.0895 14.1723 76.4337 14.5327C76.7577 14.893 77.0006 15.2933 77.1424 15.7337C77.2841 16.1941 77.3449 16.6945 77.3449 17.275C77.3449 18.8564 76.7577 20.1175 75.6036 21.0583C74.4495 21.9991 72.9309 22.4195 71.0479 22.4195Z"
          fill={color}
        />
        <path
          d="M86.2132 22.4195C84.2897 22.4195 82.7104 21.7789 81.4146 20.5178C80.139 19.2367 79.4911 17.6754 79.4911 15.8137C79.4911 13.9321 80.139 12.3708 81.4146 11.1097C82.6902 9.84856 84.2897 9.20801 86.2132 9.20801C87.4483 9.20801 88.5822 9.50827 89.5945 10.0888C90.6069 10.6693 91.3763 11.47 91.8825 12.4508L89.5136 13.812C89.2301 13.2115 88.7846 12.7311 88.1975 12.3908C87.6103 12.0505 86.9421 11.8703 86.1727 11.8703C85.0591 11.8703 84.1278 12.2507 83.3584 12.9913C82.6092 13.752 82.2245 14.6928 82.2245 15.8137C82.2245 16.9347 82.6092 17.8755 83.3584 18.6362C84.1075 19.3969 85.0389 19.7572 86.1727 19.7572C86.9219 19.7572 87.5901 19.577 88.1975 19.2367C88.8049 18.8764 89.2503 18.416 89.554 17.8355L91.923 19.1767C91.3763 20.1775 90.6069 20.9782 89.5945 21.5587C88.5822 22.1392 87.4483 22.4195 86.2132 22.4195Z"
          fill={color}
        />
        <path
          d="M97.248 11.6501C97.9364 10.0887 99.2525 9.30807 101.156 9.30807V12.2506C100.103 12.1906 99.1917 12.4308 98.4021 12.9913C97.6327 13.5518 97.2278 14.4726 97.2278 15.7737V22.0992H94.4741V9.54828H97.2278V11.6501H97.248Z"
          fill={color}
        />
        <path
          d="M107.21 7.14626C106.866 7.48656 106.461 7.66671 105.975 7.66671C105.509 7.66671 105.084 7.48656 104.74 7.14626C104.395 6.80596 104.213 6.40562 104.213 5.9252C104.213 5.4648 104.375 5.04443 104.719 4.70414C105.064 4.36384 105.468 4.18369 105.954 4.18369C106.44 4.18369 106.866 4.36384 107.19 4.70414C107.534 5.04443 107.696 5.44478 107.696 5.9252C107.736 6.3856 107.554 6.80596 107.21 7.14626ZM104.618 22.0993V9.54835H107.372V22.0993H104.618Z"
          fill={color}
        />
        <path
          d="M118.224 9.20801C119.965 9.20801 121.464 9.84856 122.699 11.1297C123.934 12.4108 124.562 13.9721 124.562 15.8137C124.562 17.6754 123.934 19.2367 122.699 20.5178C121.464 21.7989 119.965 22.4195 118.224 22.4195C116.301 22.4195 114.802 21.7189 113.77 20.3177V28.0243H111.016V9.5483H113.77V11.3499C114.802 9.92863 116.301 9.20801 118.224 9.20801ZM114.924 18.6962C115.693 19.4569 116.645 19.8372 117.799 19.8372C118.953 19.8372 119.905 19.4569 120.674 18.6962C121.444 17.9356 121.808 16.9748 121.808 15.8137C121.808 14.6527 121.423 13.6919 120.674 12.9312C119.905 12.1706 118.953 11.7903 117.799 11.7903C116.645 11.7903 115.693 12.1706 114.924 12.9312C114.154 13.6919 113.79 14.6527 113.79 15.8137C113.79 16.9748 114.154 17.9356 114.924 18.6962Z"
          fill={color}
        />
        <path
          d="M134.645 12.1506H131.486V18.1758C131.486 18.6962 131.608 19.0766 131.851 19.2968C132.094 19.517 132.438 19.6571 132.904 19.6771C133.369 19.6971 133.956 19.6971 134.645 19.6571V22.0992C132.539 22.3594 131.041 22.1793 130.13 21.5587C129.219 20.9382 128.753 19.8172 128.753 18.1758V12.1506H126.424V9.54829H128.753V6.82593L131.506 6.02523V9.54829H134.645V12.1506Z"
          fill={color}
        />
        <path
          d="M104.719 27.1035H107.635V44.6788H104.719V37.0722H97.1064V44.6788H94.1907V27.1035H97.1064V34.3098H104.719V27.1035Z"
          fill={color}
        />
        <path
          d="M112.778 39.5544C112.98 40.5152 113.446 41.2358 114.175 41.7563C114.904 42.2767 115.795 42.5169 116.847 42.5169C118.305 42.5169 119.399 41.9965 120.128 40.9556L122.395 42.2567C121.14 44.0783 119.277 44.9991 116.827 44.9991C114.762 44.9991 113.102 44.3786 111.826 43.1375C110.551 41.8964 109.923 40.315 109.923 38.3934C109.923 36.5117 110.551 34.9504 111.806 33.6893C113.061 32.4282 114.661 31.7876 116.625 31.7876C118.488 31.7876 120.026 32.4282 121.201 33.7293C122.395 35.0104 122.982 36.5918 122.982 38.4334C122.982 38.7136 122.942 39.094 122.881 39.5544H112.778ZM112.758 37.3525H120.229C120.047 36.3316 119.621 35.5509 118.953 35.0505C118.285 34.53 117.495 34.2698 116.605 34.2698C115.592 34.2698 114.742 34.55 114.074 35.0905C113.365 35.651 112.94 36.3916 112.758 37.3525Z"
          fill={color}
        />
        <path
          d="M135.07 32.1279H137.824V44.6788H135.07V42.8773C134.037 44.2985 132.559 45.0191 130.616 45.0191C128.874 45.0191 127.376 44.3786 126.141 43.0975C124.906 41.8163 124.278 40.255 124.278 38.4134C124.278 36.5518 124.906 34.9904 126.141 33.7093C127.376 32.4282 128.874 31.8077 130.616 31.8077C132.539 31.8077 134.037 32.5083 135.07 33.9095V32.1279ZM128.186 41.2759C128.955 42.0365 129.907 42.4169 131.061 42.4169C132.215 42.4169 133.167 42.0365 133.936 41.2759C134.706 40.5152 135.07 39.5544 135.07 38.3934C135.07 37.2324 134.685 36.2715 133.936 35.5109C133.167 34.7502 132.215 34.3699 131.061 34.3699C129.907 34.3699 128.955 34.7502 128.186 35.5109C127.416 36.2715 127.052 37.2324 127.052 38.3934C127.032 39.5544 127.416 40.5152 128.186 41.2759Z"
          fill={color}
        />
        <path
          d="M150.681 27.1035H153.434V44.6788H150.681V42.8772C149.648 44.2985 148.17 45.0191 146.226 45.0191C144.485 45.0191 142.987 44.3785 141.752 43.0974C140.517 41.8163 139.889 40.255 139.889 38.4134C139.889 36.5517 140.517 34.9904 141.752 33.7093C142.987 32.4282 144.485 31.8076 146.226 31.8076C148.15 31.8076 149.648 32.5082 150.681 33.9094V27.1035ZM143.776 41.2758C144.546 42.0365 145.497 42.4168 146.652 42.4168C147.806 42.4168 148.757 42.0365 149.527 41.2758C150.296 40.5152 150.66 39.5543 150.66 38.3933C150.66 37.2323 150.276 36.2715 149.527 35.5108C148.757 34.7502 147.806 34.3698 146.652 34.3698C145.497 34.3698 144.546 34.7502 143.776 35.5108C143.007 36.2715 142.643 37.2323 142.643 38.3933C142.643 39.5543 143.027 40.5152 143.776 41.2758Z"
          fill={color}
        />
        <path
          d="M158.678 35.5909C158.678 36.0113 158.901 36.3316 159.387 36.5918C159.853 36.832 160.42 37.0522 161.088 37.2123C161.756 37.3925 162.424 37.5927 163.113 37.8329C163.781 38.0731 164.368 38.4734 164.834 39.0339C165.299 39.5944 165.542 40.295 165.542 41.1558C165.542 42.3568 165.077 43.3176 164.125 43.9982C163.194 44.6788 161.999 45.0191 160.582 45.0191C159.326 45.0191 158.253 44.7589 157.362 44.2384C156.471 43.718 155.803 42.9974 155.398 42.0766L157.767 40.7154C158.213 41.9164 159.144 42.5169 160.582 42.5169C162.019 42.5169 162.748 42.0565 162.748 41.1157C162.748 40.7154 162.505 40.3951 162.04 40.1349C161.574 39.8747 161.007 39.6745 160.339 39.4943C159.671 39.3142 159.002 39.114 158.314 38.8738C157.646 38.6336 157.059 38.2532 156.593 37.7128C156.127 37.1723 155.884 36.4717 155.884 35.631C155.884 34.47 156.33 33.5492 157.221 32.8486C158.112 32.1479 159.205 31.7876 160.541 31.7876C161.594 31.7876 162.525 32.0078 163.356 32.4682C164.186 32.9286 164.813 33.5692 165.239 34.3899L162.93 35.671C162.485 34.7102 161.695 34.2298 160.541 34.2298C160.015 34.2298 159.569 34.3499 159.225 34.57C158.861 34.8303 158.678 35.1506 158.678 35.5909Z"
          fill={color}
        />
        <path
          d="M31.3429 24.2011H28.3665L22.1708 38.2333H25.5116L31.3429 24.2011Z"
          fill="#059669"
        />
        <path
          d="M12.6423 33.1967L19.6442 26.2753L17.7115 24.3642L10.7095 31.2856L12.6423 33.1967Z"
          fill="#059669"
        />
        <path
          d="M10.7062 31.2819L17.7071 38.2044L19.6402 36.2935L12.6392 29.3711L10.7062 31.2819Z"
          fill="#059669"
        />
        <path
          d="M40.0608 29.4032L33.0588 36.3246L34.9916 38.2357L41.9935 31.3143L40.0608 29.4032Z"
          fill="#059669"
        />
        <path
          d="M42.0123 31.3329L35.0114 24.4105L33.0783 26.3213L40.0793 33.2438L42.0123 31.3329Z"
          fill="#059669"
        />
        <path
          d="M32.7601 19.8574V15.5136H28.4879V12.6911H32.7601V8.52752L35.9592 8.36738V12.6911H40.1707V15.5136H35.9592V19.8574H32.7601Z"
          fill="#059669"
        />
        <path
          d="M0 45.3594V0H9.9212V3.16275H3.58378V42.0766H9.9212V45.3594H0Z"
          fill={color}
        />
        <path
          d="M53.5138 8.58307e-05V45.3595H43.5926V42.1967H49.9097V3.28294H43.5926V8.58307e-05H53.5138Z"
          fill={color}
        />
        <path
          d="M15.5297 14.2124C15.5297 13.5118 15.7727 12.9113 16.2789 12.4109C16.7648 11.9305 17.3925 11.6702 18.1214 11.6702C18.83 11.6702 19.4375 11.9104 19.9234 12.4109C20.4093 12.8913 20.6523 13.4918 20.6523 14.2124C20.6523 14.9331 20.4093 15.5336 19.9436 16.014C19.478 16.4944 18.8705 16.7346 18.1214 16.7346C17.3925 16.7346 16.7648 16.4944 16.2789 16.014C15.7929 15.5536 15.5297 14.9331 15.5297 14.2124Z"
          fill="#059669"
        />
      </svg>
    </Flexbox>
  )
}
